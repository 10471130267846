<template>
  <div class="map-active-wrapper">
    <div id="allmap" style="height: 100%; width: 100%"></div>
  </div>
</template>
<script>
// 去掉整个页面的语法错误
/* eslint-disable */
const $ = window.$
export default {
  name: 'Map',
  data() {
    return {}
  },
  props: {
    // 父级类名，用于切换样式
    addr: {
      type: String,
      require: false,
      default: ''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const that = this
      let data = that.addr
      var ShowMapType = 'off'
      var ShowNavigation = 'off'
      var ShowMarker = 'on'
      var MapLanguage = 'zh_cn'
      var MapStyle = 'normal'
      var toolBar
      var mapObj //= new AMap.Map("allmap");
      var marker = [
        {
          ItemTitle: data,
          ItemDetail: '南通人力资源大厦',
          ProvinceName: '',
          CityName: '南通市',
          Content: data,
          AdressDetail: data,
          DisplayOrder: 1,
          MapSelectType: '1',
          LngLat: '120.87988,32.056316'
        }
      ]

      function init() {
        mapObj = new AMap.Map('allmap', {
          resizeEnable: false,
          zoom: parseInt('18')
        })
        if (ShowNavigation == 'on') {
          mapObj.plugin(['AMap.ToolBar'], function () {
            toolBar = new AMap.ToolBar()
            mapObj.addControl(toolBar)
            toolBar.show()
          })
        }
        if (ShowMapType == 'on') {
          mapObj.plugin(['AMap.MapType'], function () {
            //地图类型切换
            var mapType = new AMap.MapType({
              defaultType: 0, //默认显示卫星图
              showRoad: true
            })
            mapObj.addControl(mapType)
          })
        }

        if (MapLanguage != '') {
          mapObj.setLang(MapLanguage)
        }
        if (MapStyle != '') {
          mapObj.setMapStyle(MapStyle)
        }
        if (marker != null) {
          if (marker.length > 0) {
            for (var i = 0; i < marker.length; i++) {
              var markerItem = marker[i]
              //新模式:有坐标优先读取坐标的信息
              if (markerItem.LngLat && markerItem.LngLat.indexOf(',') !== -1) {
                var locationInfo = markerItem.LngLat.split(',')
                var lng = locationInfo[0] * 1
                var lat = locationInfo[1] * 1
                addmarkerByLngLat(i, lng, lat, marker[i].DisplayOrder)
                if (i === marker.length - 1) {
                  if (marker.length > 1) {
                    mapObj.setFitView()
                  } else {
                    var lngLat = new AMap.LngLat(lng, lat)
                    mapObj.setCenter(lngLat)
                  }
                }
              } else {
                search(marker[i].AdressDetail, marker[i].CityName, marker[i].DisplayOrder)
              }
            }
          }
        }
      }

      function search(addressname, cityname, addressItemId) {
        var MSearch
        //加载地理编码插件
        mapObj.plugin(['AMap.PlaceSearch'], function () {
          MSearch = new AMap.PlaceSearch({
            //构造地点查询类
            pageSize: 10,
            pageIndex: 1,
            city: cityname //城市
          })
          //关键字查询
          MSearch.search(addressname, function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
              keywordSearch_CallBack(result, addressItemId)
            } else {
              geocoder(addressname, cityname, addressItemId)
            }
            mapObj.setZoom(parseInt('18'))
          })
        })
      }

      function geocoder(addressname, cityname, addressItemId) {
        var MGeocoder
        //加载地理编码插件
        mapObj.plugin(['AMap.Geocoder'], function () {
          MGeocoder = new AMap.Geocoder({
            city: cityname, //城市，默认：“全国”
            radius: 3000
          })
          //返回地理编码结果
          //AMap.event.addListener(MGeocoder, "complete", geocoder_CallBack);
          //地理编码
          MGeocoder.getLocation(addressname, function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
              geocoder_CallBack(result, addressItemId)
            }
          })
        })
      }

      function addmarker(i, d, itemId) {
        var lngX = d.location.getLng()
        var latY = d.location.getLat()
        var markerOption = {
          map: mapObj,
          position: new AMap.LngLat(lngX, latY)
        }
        var mar = new AMap.Marker(markerOption)
        //marker.push(new AMap.LngLat(lngX, latY));
        //构建信息窗体中显示的内容
        var info = []
        for (var i = 0; i < marker.length; i++) {
          if (marker[i].DisplayOrder == itemId) {
            info.push(
              '<div style="padding:0px 0px 0px 4px;"><b style="font-size:14px;">' + marker[i].ItemTitle + '</b>'
            )
            info.push('' + marker[i].ItemDetail + '</div></div>')
            continue
          }
        }
        var infoWindow = new AMap.InfoWindow({
          content: info.join('<br/>') //使用默认信息窗体框样式，显示信息内容
        })
        var fa = function (e) {
          if (ShowMarker == 'on') {
            infoWindow.open(mapObj, mar.getPosition())
          }
          mapObj.setCenter(mar.getPosition())
        }
        AMap.event.addListener(mar, 'click', fa)

        // 默认已点击状态
        setTimeout(function () {
          fa()
        }, 200)
      }

      function addmarkerByLngLat(i, lngX, latY, itemId) {
        var markerOption = {
          text: '南通人力资源大厦',
          map: mapObj,
          position: new AMap.LngLat(lngX, latY),
          clickable: false
        }
        AMap.Text(markerOption)
        // var mar = new AMap.Marker(markerOption)
        // //marker.push(new AMap.LngLat(lngX, latY));
        // //构建信息窗体中显示的内容
        // var info = []
        // for (var i = 0; i < marker.length; i++) {
        //   if (marker[i].DisplayOrder == itemId) {
        //     info.push('<div>' + marker[i].ItemDetail + '</div>')
        //     continue
        //   }
        // }
        // var infoWindow = new AMap.InfoWindow({
        //   content: info.join('<br/>') //使用默认信息窗体框样式，显示信息内容
        // })
        // var fa = function (e) {
        //   if (ShowMarker == 'on') {
        //     infoWindow.open(mapObj, mar.getPosition())
        //   }
        //   mapObj.setCenter(mar.getPosition())
        // }
        // AMap.event.addListener(mar, 'click', fa)
        //
        // // 默认已点击状态
        // setTimeout(function () {
        //   fa()
        // }, 200)
      }

      //地理编码返回结果展示
      function geocoder_CallBack(data, addressItemId) {
        var resultStr = ''
        //地理编码结果数组
        var geocode = new Array()
        geocode = data.geocodes
        if (geocode.length > 0) {
          addmarker(0, geocode[0], addressItemId)
        }
        mapObj.setFitView()
      }

      function keywordSearch_CallBack(data, addressItemId) {
        var poiArr = data.poiList.pois
        var resultCount = poiArr.length
        if (resultCount > 0) {
          for (var i = 0; i < resultCount; i++) {
            addmarker(i, poiArr[i], addressItemId)
            break // 只取第一条查询到的记录显示
          }
          mapObj.setFitView()
        }
      }

      $(function () {
        const hack = setInterval(function () {
          if ($('#allmap').is(':visible')) {
            init()
            clearInterval(hack)
          }
        }, 1000)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.map-active-wrapper {
  width: 100%;
  height: 300px;
}
</style>
